<template>
  <select :id="name" :name="name" v-model="selectedValue" class="dropdown-list">
    <DropdownOption
      v-for="option in options"
      :value="optionValue ? option[optionValue] : option"
      :label="optionLabel ? getLabel(option, optionLabel) : option"
      :key="option"
    />
  </select>
</template>

<script>
import DropdownOption from "@shared_components/form/DropdownOption.vue";
export default {
  name: "DropdownList",
  props: [
    "options",
    "name",
    "value",
    "optionValue",
    "optionLabel",
    "initialValue",
  ],
  components: {
    DropdownOption,
  },
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    getLabel(option, optionLabel) {
      const splitLabel = optionLabel.split(".");
      let currentValue = null;
      splitLabel.forEach((label) => {
        currentValue = currentValue ? currentValue[label] : option[label];
      });

      return currentValue;
    },
  },
};
</script>

<style scoped>
.dropdown-list {
  display: block;
  width: 100%;
  height: 57px;
  font-size: 21px;
  padding: 10px;
}
</style>
