<template>
  <p class="error-message">{{ text }}</p>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: ["text"],
};
</script>

<style scoped>
.error-message {
  text-align: center;
  border: 1px solid #eec8cb;
  color: #692326;
  background: #f3d8da;
  padding: 20px;
}
</style>
