<template>
  <Title>Questionnaire</Title>

  <p>Thank you for agreeing to join our volunteer database.</p>

  <p>
    Access to our database is restricted to designated members of our team and
    is not linked to your NHS records. We will only store the information you
    provide to us below
  </p>

  <Heading>Contact Details</Heading>

  <Section>
    <Subsection>
      <FormLabel>Name</FormLabel>
      <TextField
        :value="name"
        @update:value="assignValue('name', $event)"
        name="name"
    /></Subsection>

    <Subsection>
      <FormLabel>Email address</FormLabel>
      <TextField
        :value="email"
        @update:value="assignValue('email', $event)"
        name="email-address"
      />
    </Subsection>

    <Subsection>
      <FormLabel>Address</FormLabel>
      <TextField
        :value="addressLine1"
        @update:value="assignValue('addressLine1', $event)"
      />
      <TextField
        :value="addressLine2"
        @update:value="assignValue('addressLine2', $event)"
      />
      <TextField
        :value="addressLine3"
        @update:value="assignValue('addressLine3', $event)"
      />
    </Subsection>

    <Subsection>
      <FormLabel>Post Code</FormLabel>
      <TextField
        :value="postcode"
        @update:value="assignValue('postcode', $event)"
      />
    </Subsection>

    <Subsection>
      <FormLabel>Telephone Number</FormLabel>

      <TelephoneField
        :value="telephoneNumber"
        @onChange="assignValue('telephoneNumber', $event)"
        maxLength="13"
      />
    </Subsection>
  </Section>

  <Section>
    <FormLabel>How would you prefer to be contacted?</FormLabel>
    <RadioButtonGroup
      :options="contactPreferences"
      :value="contactPreference"
      @update:value="assignValue('contactPreference', $event)"
      name="contactPreference"
    />
  </Section>

  <Section>
    <FormLabel>Is English your first language from birth?</FormLabel>
    <RadioButtonGroup
      :options="['Yes', 'No']"
      :value="isEnglishFirstLanguage"
      @update:value="assignValue('isEnglishFirstLanguage', $event)"
      name="isEnglishFirstLanguage"
    />
  </Section>

  <Section v-if="isEnglishFirstLanguage === 'No'">
    <FormLabel>What is your first language?</FormLabel>
    <TextField
      :value="otherFirstLanguage"
      @update:value="assignValue('otherFirstLanguage', $event)"
    />
  </Section>
</template>

<script>
import Title from "@shared_components/Title.vue";
import Heading from "@shared_components/Heading.vue";
import TextField from "@shared_components/form/TextField.vue";
import Section from "@shared_components/form/Section.vue";
import RadioButtonGroup from "@shared_components/form/RadioButtonGroup.vue";
import FormLabel from "@shared_components/form/FormLabel.vue";
import Subsection from "@shared_components/form/Subsection.vue";
import TelephoneField from "@shared_components/form/TelephoneField.vue";

import { mapState } from "vuex";

export default {
  name: "ContactDetails",
  props: ["volunteer"],
  components: {
    Title,
    Heading,
    TextField,
    RadioButtonGroup,
    Section,
    FormLabel,
    Subsection,
    TelephoneField
  },
  data() {
    return {
      contactPreferences: ["Email", "Telephone", "Post"],
    };
  },
  computed: {
    ...mapState("volunteer", [
      "name",
      "email",
      "addressLine1",
      "addressLine2",
      "addressLine3",
      "postcode",
      "telephoneNumber",
      "contactPreference",
      "isEnglishFirstLanguage",
      "otherFirstLanguage",
    ]),
  },
  methods: {
    assignValue(name, value) {
      const payload = {
        prop: name,
        value: value,
      };
      this.$store.commit("volunteer/assignValue", payload);
    },
  },
};
</script>

<style scoped>
button {
  display: block;
}

h3 {
  margin: 0;
}

label {
  display: block;
  font-size: 24px;
  font-weight: bold;
  color: #222222;
}
</style>
