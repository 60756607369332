<template>
  <label class="form-label"><slot></slot></label>
</template>

<script>
export default {
  name: "FormLabel",
};
</script>

<style scoped>
.form-label {
  display: block;
  font-size: 19px;
  font-weight: bold;
  color: #222222;
  padding-bottom: 10px;
}
</style>
