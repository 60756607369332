<template>
  <input
    type="radio"
    :value="valueField ? option[valueField] : option"
    v-model="radioValue"
    :id="labelField ? option[labelField] + name : option + name"
    :name="name"
  />
  <label :for="labelField ? option[labelField] + name : option + name">
    {{ labelField ? option[labelField] : option }}
  </label>
</template>

<script>
export default {
  name: "RadioButton",
  props: ["option", "value", "name", "labelField", "valueField"],
  computed: {
    radioValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$parent.$emit("update:value", value);
      },
    },
  },
};
</script>

<style scoped>
input[type="radio"] {
  transform: scale(2);
}

label {
  display: block;
  font-weight: bold;
  border: 1px solid black;
  padding: 10px;
  background: white;
}

label:hover {
  cursor: pointer;
}

input[type="radio"] {
  visibility: hidden;
}
input {
  position: absolute;
}
input[type="radio"]:checked + label {
  background: #1f7da8;
  border-color: black;
  color: white;
}
</style>
