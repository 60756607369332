<template>
  <div class="home">
    <Title>Audiology study volunteers</Title>
    <Text>
      The information below tells you all about our Hearing Health Research
      Volunteer Database. If you decide, after reading this information, that
      you would like to join the Volunteer Database, please fill in the consent
      form at the bottom of the screen. On completion of the consent form, you
      will be directed to a hearing health volunteer questionnaire.
    </Text>

    <ScrollableBox>
      <VolunteerInformation />
      <AboutTheDatabase />
      <Involvement />
      <DataProtection />
      <ContactDetails />
      <Complaints />
      <CovidInformation />
    </ScrollableBox>

    <ConsentForm ref="consentform" />
    <ErrorMessage v-if="errorMessage" :text="errorMessage" />

    <PrimaryButton @click="onGetStartedClick">Get Started</PrimaryButton>
  </div>
</template>

<script>
import Title from "@shared_components/Title.vue";
import Text from "@shared_components/Text.vue";
import ScrollableBox from "@shared_components/ScrollableBox.vue";
import AboutTheDatabase from "./ConsentPageContent/AboutTheDatabase.vue";
import Involvement from "./ConsentPageContent/Involvement.vue";
import DataProtection from "./ConsentPageContent/DataProtection.vue";
import ContactDetails from "./ConsentPageContent/ContactDetails.vue";
import Complaints from "./ConsentPageContent/Complaints.vue";

import CovidInformation from "./ConsentPageContent/CovidInformation.vue";
import VolunteerInformation from "./ConsentPageContent/VolunteerInformation.vue";
import PrimaryButton from "@shared_components/PrimaryButton.vue";
import ConsentForm from "./ConsentPageContent/ConsentForm.vue";
import ErrorMessage from "@shared_components/ErrorMessage.vue";
import router from "@volunteer/router";
import { mapMutations } from "vuex";
export default {
  name: "ConsentPage",
  components: {
    Title,
    Text,
    ScrollableBox,
    AboutTheDatabase,
    Involvement,
    DataProtection,
    ContactDetails,
    Complaints,
    VolunteerInformation,
    PrimaryButton,
    ConsentForm,
    ErrorMessage,
    CovidInformation,
  },
  data() {
    return {
      errorMessage: null,
    };
  },
  created() {
    this.resetVolunteerStore();
  },
  methods: {
    ...mapMutations("volunteer", ["resetVolunteerStore", "assignValue"]),
    onGetStartedClick() {
      const response = this.$refs.consentform.submit();
      if (response.success) {
        this.errorMessage = null;
        const payload = {
          prop: "consented",
          value: true,
        };
        this.assignValue(payload);

        router.push({
          name: "Questionnaire",
        });
      } else {
        this.errorMessage = response.errorMessage;
      }
    },
  },
};
</script>
