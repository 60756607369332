<template>
  <option class="dropdown-option" :value="value">{{ label }}</option>
</template>

<script>
export default {
  name: "DropdownOption",
  props: ["value", "label"],
};
</script>
