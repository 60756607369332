<template>
  <button class="primary-button" type="button" :disabled="isDisabled">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "PrimaryButton",
  props: ["isDisabled"],
};
</script>

<style scoped>
.primary-button {
  padding: 0.7em 1.2em;
  background: #28a0d8;
  color: white;
  display: inline-block;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  font-size: 1em;
  margin: 1em 0;
  font-weight: bold;
}

.primary-button:hover {
  background: #2187b6;
}

.primary-button:disabled {
  background: #b4b4b6;
  cursor: default;
}
</style>
