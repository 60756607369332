<template>
  <div class="checkbox-item">
    <input type="checkbox" v-model="inputValue" />
    <span class="label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  components: {},
  props: ["label", "item", "selectedValues", "selectedValue"],
  computed: {
    inputValue: {
      get() {
        let isChecked = false;

        if (this.selectedValues) {
          isChecked = this.selectedValues.find((value) => {
            return value.id === this.item.id;
          });
        } else if (this.selectedValue) {
          isChecked = this.selectedValue;
        }

        return !!isChecked;
      },
      set(checked) {
        const item = this.item;
        this.$parent.$emit("oncheckedchanged", { checked, item });
      },
    },
  },
};
</script>

<style scoped>
.checkbox-item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.checkbox-item input {
  margin-top: 5px;
  cursor: pointer;
}
.checkbox-item .label {
  padding-left: 20px;
}

input[type="checkbox"] {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
}
</style>
