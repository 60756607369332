<template>
  <div
    class="context-menu"
    v-if="show"
    :style="style"
    ref="context"
    @blur="close"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "PopupMenuModalModal",
  props: {
    display: Boolean,
  },
  data() {
    return {
      left: 0,
      top: 0,
      show: false,
    };
  },
  computed: {
    style() {
      return {
        top: this.top + "px",
        left: this.left + "px",
      };
    },
  },
  methods: {
    close() {
      this.show = false;
      this.left = 0;
      this.top = 0;
    },
    open(width, height) {
      this.left = width;
      this.top = height + window.pageYOffset;

      this.show = true;

      // @ts-ignore
      this.$nextTick(() => {
        const element = this.$refs["context"];
        element.focus();
      });
    },
  },
};
</script>
<style>
.context-menu {
  position: absolute;
  background: white;
  z-index: 999;
  outline: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
}
</style>
