<template>
  <Section>
    <FormLabel>Do you have tinnitus?</FormLabel>
    <RadioButtonGroup
      :options="yesNoNotSureEnum"
      labelField="label"
      valueField="value"
      :value="tinnitusSufferer"
      @update:value="assignValue('tinnitusSufferer', $event)"
      name="tinnitusSufferer"
    />
  </Section>

  <Section v-show="tinnitusSufferer == 'YES'">
    <FormLabel>When is your tinnitus present?</FormLabel>
    <RadioButtonGroup
      :options="['Always', 'Sometimes']"
      :value="tinnitusOccurence"
      @update:value="assignValue('tinnitusOccurence', $event)"
      name="tinnitusOccurence"
    />
  </Section>
</template>

<script>
import Section from "@shared_components/form/Section.vue";
import RadioButtonGroup from "@shared_components/form/RadioButtonGroup.vue";
import FormLabel from "@shared_components/form/FormLabel.vue";
import YesNoNotSureEnum from "@volunteer/enums/yesNoNotSureEnum.js";
import { mapState } from "vuex";

export default {
  name: "TinnitusQuestions",
  components: {
    Section,
    RadioButtonGroup,
    FormLabel,
  },
  computed: {
    ...mapState("volunteer", ["tinnitusSufferer", "tinnitusOccurence"]),
  },
  data() {
    return {
      yesNoNotSureEnum: YesNoNotSureEnum,
    };
  },
  methods: {
    assignValue(name, value) {
      const payload = {
        prop: name,
        value: value,
      };
      this.$store.commit("volunteer/assignValue", payload);
    },
  },
};
</script>
