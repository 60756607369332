<template>
  <div class="scrollable-box">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ScrollableBox",
};
</script>

<style scoped>
.scrollable-box {
  overflow-y: scroll;
  height: 50vh;
  border: 1px solid #bbbbbb;
  padding: 10px 40px 10px 40px;
}

@media only screen and (max-width: 600px) {
  div {
    padding: 5px 0px 0px 5px;
  }
}
</style>
