<template>
  <div class="subsection">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Section",
  props: ["label"],
};
</script>

<style scoped>
.subsection {
  padding-bottom: 20px;
}
</style>
