import axios from "axios";
const cookies = require("browser-cookies");
import { ConstantService } from "./constant.service";

const ApiService = {
  async initialise() {
    axios.defaults.baseURL = ConstantService.API_URL;
    axios.defaults.withCredentials = true;
    axios.interceptors.request.use(function (config) {
      config.headers["X-XSRF-TOKEN"] = cookies.get("XSRF-TOKEN");
      return config;
    });
  },

  query(resource, params) {
    return axios.get(resource, { params: params }).catch((error) => {
      console.log("erro", error);
      throw new Error(`PPIE ApiService ${error}`);
    });
  },

  getTemplateFromSlug(slug, resource) {
    if (slug === "") {
      return `${resource}`;
    } else {
      return `${resource}/${slug}`;
    }
  },

  get(resource, slug = "") {
    var template = this.getTemplateFromSlug(slug, resource);
    return axios.get(template).catch((error) => {
      console.log("error", error);
      throw new Error(`PPIE ApiService ${error}`);
    });
  },

  post(resource, params, config = {}) {
    return axios.post(`${resource}`, params, config);
  },

  postPlainText(url, text, additionalConfig) {
    let requestConfig = {
      method: "post",
      url: url,
      data: text,
      headers: { "Content-Type": "text/plain" },
    };

    if (additionalConfig) {
      requestConfig = { ...requestConfig, ...additionalConfig };
    }

    return axios(requestConfig);
  },

  postFormData(url, formData) {
    return axios({
      method: "post",
      url: url,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return axios.put(`${resource}`, params);
  },

  patch(resource, params) {
    return axios({
      method: "patch",
      url: resource,
      data: params,
      headers: { "Content-Type": "application/merge-patch+json" },
    });
  },

  delete(resource) {
    return axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
