import ApiService from "@shared_services/api.service.js";

const QuestionnaireService = {
  async submitQuesionnaire(
    volunteerContactDetails,
    volunteerInclusivityDetails, 
    volunteerInformation,
    volunteerDevice
  ) {
    const questionnaireAnswers = {
      ...volunteerContactDetails,
      ...volunteerInclusivityDetails,
      volunteerInformation: {
        ...volunteerInformation,
        volunteerDevice: {
          ...volunteerDevice,
        },
      },
    };
    return ApiService.post("volunteers", questionnaireAnswers);
  },
};

export default QuestionnaireService;
