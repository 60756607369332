<template>
  <h2>
    <slot></slot>
  </h2>
</template>

<script>
export default {
  name: "Heading",
};
</script>

<style scoped>
h2 {
  color: #111111;
  font-size: 28px;
}
</style>
