export const QuestionnaireValidation = {
  validateContactPage(volunteerStore) {
    let isValid = true;
    let errors = {};
    if (!volunteerStore.name) {
      errors["Name"] = "Name is required";
    }

    if (!volunteerStore.email) {
      errors["Email"] = "Email is required";
    } else {
      if (!this.validateEmail(volunteerStore.email)) {
        errors["Email"] = "The format of the email address is not correct";
      }
    }

    if (Object.entries(errors).length > 0) {
      isValid = false;
    }

    return { errors, isValid };
  },
  validateOnSubmit(volunteerStore) {
    let { errors, isValid } = this.validateContactPage(volunteerStore);

    const dob = volunteerStore.dob;

    const sexAtBirth = volunteerStore.sexAtBirth;

    if (!dob) {
      errors["DOB"] = "Date of birth is required";
    }

    if (!sexAtBirth) {
      errors["SAB"] = "Sex at birth is required";
    }

    if (Object.entries(errors).length > 0) {
      isValid = false;
    }

    return { errors, isValid };
  },
  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
};
