<template>
  <div class="radio-button-group">
    <RadioButton
      v-for="option in options"
      :option="option"
      :labelField="labelField"
      :valueField="valueField"
      :key="option"
      :value="value"
      :name="name"
    />
  </div>
</template>

<script>
import RadioButton from "@shared_components/form/RadioButton.vue";
export default {
  name: "RadioButtonGroup",
  components: { RadioButton },
  props: ["options", "value", "name", "labelField", "valueField"],
};
</script>

<style scoped>
.radio-button-group {
  display: flex;
  column-gap: 15px;
}
</style>
