<template>
  <Heading>About the Database</Heading>

  <div>
    <Subheading>What is the purpose of the database</Subheading>
    <Text>
      We are a multi-disciplinary team with an internationally recognised
      hearing health research programme that makes a real difference to the
      lives of people with hearing health needs. However, when we are carrying
      out research studies, it is sometimes difficult to find enough people to
      take part. This is especially the case if we have a short period of time
      to do the research. Also, some research studies need participants to be a
      certain age or have a particular level of hearing. Having a database of
      people we can contact quickly means that a research study is more likely
      to be completed on time and have more meaningful results.
    </Text>

    <Subheading>Do I have to have a hearing problem to be involved?</Subheading>

    <Text>
      No. Sometimes our studies need people whose hearing thresholds are within
      the “normal” range to take part in our studies.
    </Text>

    <Subheading
      >Who manages the Hearing Health Research Volunteer Database?</Subheading
    >
    <Text>
      The database is managed by Research Audiologists, Helen Whiston and
      Melanie Lough (see below for contact details). Researchers from within
      Manchester BRC and ManCAD are allowed access to a restricted version of
      the database in order to identify potential participants for their studies
      (described in more detail below). However, access is only granted by the
      database managers once it has been confirmed that the researcher is part
      of Manchester BRC/ManCAD, and has ethical approval for their particular
      study.
    </Text>

    <Subheading>Disclosure and Barring Service (DBS) check</Subheading>

    <Text>
      The database managers (Research Audiologists) have undergone satisfactory
      DBS checks for working with vulnerable adults.
    </Text>
    <Subheading
      >Who has reviewed the Hearing Health Volunteer Database?
    </Subheading>
    <Text
      >This Project Has Been Approved by the University of Manchester’s Research
      Ethics Committee 06/11/17 [Ref: 2017-2815-4078]</Text
    >
  </div>
</template>
<script>
import Heading from "@shared_components/Heading.vue";
import Subheading from "@shared_components/Subheading.vue";
import Text from "@shared_components/Text.vue";
export default {
  name: "AboutTheDatabase",
  components: {
    Heading,
    Subheading,
    Text,
  },
};
</script>
