<template>
  <Section>
    <FormLabel>Do you wear a hearing device?</FormLabel>
    <RadioButtonGroup
      :options="['Yes', 'No']"
      :value="hasHearingDevice"
      @update:value="assignValue('hasHearingDevice', $event)"
      name="hasHearingDevice"
    />
  </Section>

  <Section v-show="hasHearingDevice == 'Yes'">
    <FormLabel>Which side do you wear your device on?</FormLabel>
    <RadioButtonGroup
      :options="['Left', 'Right', 'Both']"
      :value="devicePosition"
      @update:value="assignValue('devicePosition', $event)"
      name="devicePosition"
    />
  </Section>

  <Section v-show="hasHearingDevice == 'Yes'">
    <FormLabel>What type of device(s) do you use?</FormLabel>
    <CheckboxButtonGroup
      v-if="deviceTypesValues.length > 0"
      :options="deviceTypesValues"
      :selectedValues="deviceTypes"
      name="deviceTypes"
      @oncheckedchanged="updateHearingDeviceType"
      labelField="deviceName"
    />
  </Section>

  <Section v-show="hasHearingDevice == 'Yes'">
    <FormLabel>Other(please specify)</FormLabel>
    <TextField
      :value="otherDevice"
      @update:value="assignValue('otherDevice', $event)"
    />
  </Section>
</template>

<script>
import Section from "@shared_components/form/Section.vue";
import RadioButtonGroup from "@shared_components/form/RadioButtonGroup.vue";
import CheckboxButtonGroup from "@shared_components/form/CheckboxButtonGroup.vue";
import TextField from "@shared_components/form/TextField.vue";
import FormLabel from "@shared_components/form/FormLabel.vue";
import InputValues from "@volunteer/services/inputvalues.service.js";

import { mapMutations, mapState } from "vuex";

export default {
  name: "HearingDeviceQuestions",
  components: {
    Section,
    RadioButtonGroup,
    CheckboxButtonGroup,
    TextField,
    FormLabel,
  },
  data() {
    return {
      deviceTypesValues: [],
    };
  },
  async created() {
    this.deviceTypesValues = await InputValues.getDeviceTypes();
  },
  computed: {
    ...mapState("volunteer", [
      "hasHearingDevice",
      "devicePosition",
      "deviceTypes",
      "otherDevice",
    ]),
  },
  methods: {
    ...mapMutations("volunteer", ["updateHearingDeviceType"]),
    assignValue(name, value) {
      const payload = {
        prop: name,
        value: value,
      };
      this.$store.commit("volunteer/assignValue", payload);
    },
  },
};
</script>

<style scoped>
/deep/ .checkbox-item {
  padding: 0px !important;
  margin: 0px !important;
  padding-bottom: -10px;
}

/deep/ .checkbox-item .label {
  padding-left: 10px !important;
  font-size: 21px;
}
</style>
