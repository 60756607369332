<template>
  <Heading>Volunteer Information</Heading>

  <Text>
    Our researchers are part of the Hearing Health theme of the National
    Institute of Health Research (NIHR) Biomedical Researcher Centre (BRC) in
    Manchester, and the Manchester Centre for Audiology and Deafness (ManCAD).
    We have a database of people who are interested in taking part in research
    related to hearing health. We currently have adults with all levels of
    hearing on our database.
  </Text>

  <Text>
    Before you decide whether to join the Hearing Health Research Volunteer
    Database, it is important for you to understand why it exists and what
    joining it will involve. Please take time to read the following information
    carefully and discuss it with others if you wish. Please ask if there is
    anything that is not clear or if you would like more information. Take time
    to decide whether or not you wish to take part. Thank you for taking the
    time to read this.
  </Text>
</template>

<script>
import Heading from "@shared_components/Heading.vue";
import Text from "@shared_components/Text.vue";
export default {
  name: "VolunteerInformation",
  components: {
    Heading,
    Text,
  },
};
</script>
