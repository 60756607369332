import { createStore } from "vuex";

function isEmptyString(string) {
  return string === null || string.length === 0 || !string.trim();
}
const getBooleanValue = (input) => {
  switch (input) {
    case "Yes":
      return "True";
    case "No":
      return "False";
    case "Not sure":
      return null;
    default:
      return null;
  }
};

const getEarSide = (input) => {
  if (!input || input === "Not sure") {
    return null;
  } else {
    return input.toUpperCase();
  }
};

const resetOtherFirstLanguage = (state) => {
  state.otherFirstLanguage = null;
};

const resetDifficultyHearing = (state) => {
  state.hearingLossEar = null;
  state.hearingLossSince = "";
};

const resetHearingDevice = (state) => {
  state.devicePosition = null;
  state.deviceTypes = [];
  state.otherDevice = null;
};

const resetTinnitus = (state) => {
  state.tinnitusOccurence = null;
};

const resetEthnicityOther = (state) => {
  state.ethnicityOther = null;
};

const resetJobTitle = (state) => {
  state.jobTitle = null;
};

const resetOtherEmploymentStatus = (state) => {
  state.employmentStatusOther = null;
};

const resetOtherGender = (state) => {
  state.genderOther = null;
};
const toIdObject = (field) => {
  if (field) {
    return {
      id: field,
    };
  }
  return null;
};

const getAddress = (state) => {
  let address = [state.addressLine1, state.addressLine2, state.addressLine3];

  address = address.filter((part) => {
    if (isEmptyString(part)) {
      return false;
    }
    return true;
  });

  return address.join(",");
};

const volunteerInitialState = {
  consented: false,
  name: "",
  email: "",
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  postcode: null,
  telephoneNumber: null,
  contactPreference: null,
  isEnglishFirstLanguage: "",
  otherFirstLanguage: null,
  sexAtBirth: null,
  difficultyHearing: null,
  hearingLossEar: null,
  hearingLossSince: "",
  hasHearingDevice: "",
  devicePosition: null,
  deviceTypes: [],
  otherDevice: null,
  tinnitusSufferer: null,
  tinnitusOccurence: null,
  balanceDisorder: null,
  balanceDisorderDetails: null,
  handedness: null,
  computerAccess: "",
  otherRelevantInformation: null,
  heardFrom: "",
  dob: null,
  volunteerConsentStatements: [],
  ethnicity: null,
  ethnicityOther: null,
  religion: null,
  employmentStatus: null,
  jobTitle: null,
  employmentStatusOther: null,
  educationLevel: null,
  gender: null,
  genderOther: null,
  sexual_orientation: null,
  disability: null,
};

const volunteerModule = {
  namespaced: true,
  state: () => ({
    ...volunteerInitialState,
  }),
  mutations: {
    assignValue(state, payload) {
      let value = payload.value;
      if (
        payload.prop === "isEnglishFirstLanguage" &&
        payload.value === "Yes"
      ) {
        resetOtherFirstLanguage(state);
      }

      if (payload.prop === "difficultyHearing" && payload.value !== "Yes") {
        resetDifficultyHearing(state);
      }

      if (payload.prop === "hasHearingDevice" && payload.value !== "Yes") {
        resetHearingDevice(state);
      }

      if (payload.prop === "tinnitusSufferer" && payload.value !== "Yes") {
        resetTinnitus(state);
      }

      if (payload.prop === "dob" && payload.value) {
        let date = new Date(payload.value);
        date.setUTCHours(0, 0, 0, 0);
        value = date.toISOString();
      }

      if (payload.prop === "ethnicity") {
        resetEthnicityOther(state);
      }

      if (payload.prop === "employmentStatus") {
        resetJobTitle(state);
        resetOtherEmploymentStatus(state);
      }

      if (payload.prop === "gender") {
        resetOtherGender(state);
      }

      state[payload.prop] = value;
    },
    updateHearingDeviceType(state, payload) {
      if (payload.checked) {
        state.deviceTypes = [...state.deviceTypes, payload.item];
      } else {
        state.deviceTypes = state.deviceTypes.filter((item) => {
          return item != payload.item;
        });
      }
    },
    setVolunteerConsentStatements(state, payload) {
      payload.forEach((consentStatement) => {
        state.volunteerConsentStatements.push({
          consentStatement: {
            id: consentStatement.id,
          },
        });
      });
    },
    resetVolunteerStore(state) {
      Object.assign(state, volunteerInitialState);
    },
    resetDifficultyHearing(state) {
      state.hearingLossEar = null;
      state.hearingLossSince = "";
    },
    resetOtherFirstLanguage(state) {
      state.otherFirstLanguage = null;
    },
  },
  actions: {},
  getters: {
    getContactDetails: (state) => {
      return {
        name: state.name,
        email: state.email,
        address: getAddress(state),
        dateOfBirth: state.dob,
        postcode: state.postcode,
        telephoneNumber: state.telephoneNumber,
        otherFirstLanguage: state.otherFirstLanguage,
        isEnglishFirstLanguage: getBooleanValue(state.isEnglishFirstLanguage),
        contactPreference: state.contactPreference
          ? state.contactPreference.toUpperCase()
          : null,
        sexAtBirth: state.sexAtBirth ? state.sexAtBirth.toUpperCase() : null,
        computerAccess: getBooleanValue(state.computerAccess),
        handedness: state.handedness ? state.handedness.toUpperCase() : null,
        advertisementSource: state.heardFrom
          ? {
              id: state.heardFrom,
            }
          : null,
        volunteerConsentStatements: state.volunteerConsentStatements,
      };
    },
    getInclusivityDetails: (state) => {
      return {
        ethnicity: toIdObject(state.ethnicity),
        religion: toIdObject(state.religion),
        employmentStatus: toIdObject(state.employmentStatus),
        educationLevel: toIdObject(state.educationLevel),
        gender: toIdObject(state.gender),
        sexualOrientation: toIdObject(state.sexual_orientation),
        disability: state.disability ? state.disability : null,
        ethnicityOther: state.ethnicityOther,
        jobTitle: state.jobTitle,
        employmentStatusOther: state.employmentStatusOther,
        genderOther: state.genderOther,
      };
    },
    getVolunteerInformation: (state) => {
      return {
        difficultyHearing: state.difficultyHearing,
        hearingLossEar: state.hearingLossEar,
        tinnitusSufferer: state.tinnitusSufferer,
        tinnitusOccurence: state.tinnitusOccurence
          ? state.tinnitusOccurence.toUpperCase()
          : null,
        balanceDisorder: state.balanceDisorder,
        balanceDisorderDetails: state.balanceDisorderDetails,
        hearingLossSince: state.hearingLossSince
          ? {
              id: state.hearingLossSince,
            }
          : null,
        otherRelevantInformation: state.otherRelevantInformation,
      };
    },
    getVolunteerDevice: (state) => {
      return {
        hasHearingDevice: getBooleanValue(state.hasHearingDevice),
        devicePosition: getEarSide(state.devicePosition),
        otherDevice: state.otherDevice,
        deviceTypes:
          state.deviceTypes.length > 0 ? [...state.deviceTypes] : null,
      };
    },
  },
};

const store = createStore({
  modules: {
    volunteer: volunteerModule,
  },
});

export default store;
