<template>
  <Heading>Consent</Heading>
  <CheckboxButtonGroup
    :options="consentStatements"
    :selectedValues="checkedItems"
    name="checkedItems"
    @oncheckedchanged="checked"
    labelField="text"
  />

  <Subheading>Data Protection</Subheading>
  <p class="bold-text">
    The personal information we collect and use to conduct this research will be
    processed in accordance with UK data protection law as explained in the
    Participant Infromation Sheet and the
    <a href="https://documents.manchester.ac.uk/display.aspx?DocID=37095"
      >Privacy Notice for Researcher Participants</a
    >
  </p>
</template>

<script>
import Heading from "@shared_components/Heading.vue";
import Subheading from "@shared_components/Subheading.vue";
import CheckboxButtonGroup from "@shared_components/form/CheckboxButtonGroup.vue";
import { ConsentService } from "@volunteer/services/consent.service.js";
import { mapMutations } from "vuex";
export default {
  name: "ConsentForm",
  components: {
    Heading,
    CheckboxButtonGroup,
    Subheading,
  },
  data() {
    return {
      consentStatements: [],
      checkedItems: [],
    };
  },
  async created() {
    this.consentStatements = await ConsentService.getConsentStatements();
  },
  methods: {
    ...mapMutations("volunteer", ["setVolunteerConsentStatements"]),
    submit() {
      if (this.checkedItems.length === this.consentStatements.length) {
        this.setVolunteerConsentStatements(this.checkedItems);
        return { success: true };
      } else {
        return {
          errorMessage:
            "All six boxes on the consent form must be ticked to continue to the questionnaire.",
        };
      }
    },

    checked(event) {
      const item = event.item;

      if (event.checked) {
        this.checkedItems.push(item);
      } else {
        this.checkedItems = this.checkedItems.filter((item) => {
          return item.id != item.id;
        });
      }
    },
  },
};
</script>

<style scoped>
/deep/ .checkbox-item .label {
  color: #333;
  font-size: 16px;
}

.bold-text {
  font-weight: bold;
  color: black;
}
</style>
