<template>
  <Heading>Additional Covid – 19 information</Heading>
  <Text>
    Due to the current COVID-19 pandemic, we have made some adjustments to
    ensure we are adhering to the latest government advice in relation to social
    distancing as well as taking all reasonable precautions in terms of limiting
    the spread of the virus. You should carefully consider all of the
    information provided below before deciding if you still want to join the
    database. If you have any additional queries about any of the information
    provided, please speak to the database managers.
  </Text>

  <Subheading
    >Are there any additional considerations that I need to know about before
    deciding whether I should take part?</Subheading
  >
  <Text>
    Joining our database does not require you to attend the University of
    Manchester as signing up is completed online. However if you choose to
    attend for a hearing assessment this requires close proximity between
    researcher and participant. We will follow UK Audiology professional
    guidance to minimise these risks. Only state-registered Audiologists will
    carry out these close proximity procedures.
  </Text>
  <Text
    >Participants will be asked to confirm that they and their household/bubble
    are well and have no symptoms of COVID-19 and are not self-isolating. You
    should not attend if you are in a vulnerable group.</Text
  >

  <Subheading
    >What additional steps will you take to keep me safe while I take
    part?</Subheading
  >
  <ul>
    <li>
      Both Audiologists and participants will wear a high-quality face mask
      (Type II R) for the duration of any close proximity work, which will be
      provided.
    </li>
    <li>
      All equipment that comes into contact with Audiologist and/or participant
      will be disinfected at the end of a session.
    </li>
    <li>
      Hand sanitiser, disinfectant wipes and PPE will be readily available in
      all testing rooms.
    </li>
  </ul>
  <Subheading
    >Is there any additional information that I need to know?</Subheading
  >
  <ul>
    <li>Test rooms will be well-ventilated before and after use.</li>
    <li>
      Researchers who will carry out close proximity Audiological procedures are
      healthy, non-vulnerable individuals who are fully vaccinated for COVID-19
      and they will take twice weekly lateral flow tests to monitor their risk
      of infection.
    </li>
    <li>
      Volunteers attending the University of Manchester for a hearing test will
      be asked to phone the Research Audiologist on their arrival so that they
      can be brought directly into their appointment and avoid waiting in busy
      areas of the building.
    </li>
  </ul>
  <Subheading>Additional data use?</Subheading>
  <Text
    >We may have to provide contact details to NHS Track and Trace if it becomes
    necessary or equivalent details for another country.</Text
  >
  <Subheading>What if the Government Guidance changes?</Subheading>
  <Text
    >In these circumstances we will advise you depending on the government
    guidance at the time which may include postponing contact.</Text
  >

  <Subheading>What if I have additional queries?</Subheading>
  <Text>Please contact the database managers, contact details above.</Text>
</template>

<script>
import Heading from "@shared_components/Heading.vue";
import Subheading from "@shared_components/Subheading.vue";
import Text from "@shared_components/Text.vue";
export default {
  name: "CovidInformation",
  components: {
    Heading,
    Text,
    Subheading,
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}

ul li::before {
  content: "\25A0";
  color: #4ea1d6;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
li {
  padding-bottom: 10px;
  color: #333;
}
</style>
