<template>
  <router-view />

  <div v-if="Object.entries(errors).length" class="validation-message">
    <p>Please correct the following errors before proceeding:</p>
    <ul>
      <li v-for="key in Object.entries(errors)" v-bind:key="key">
        {{ key[1] }}
      </li>
    </ul>
  </div>

  <PrimaryButton @click="onButtonClick">{{ buttonName }}</PrimaryButton>
</template>

<script>
import PrimaryButton from "@shared_components/PrimaryButton.vue";
import router from "@volunteer/router";
import store from "@volunteer/store";

import { QuestionnaireValidation } from "@volunteer/services/validation.service.js";
import QuestionnaireService from "@volunteer/services/questionnaire.service.js";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Questionnaire",
  components: {
    PrimaryButton,
  },
  beforeRouteUpdate() {
    this.errors = {};
  },
  beforeRouteEnter(to, from, next) {
    const consented = store.state.volunteer.consented;
    if (consented) {
      next();
    } else {
      if (!from.name) {
        next("/");
      } else {
        next(false);
      }
    }
  },
  created() {
    router.replace({ name: "ContactDetails" });
  },
  data() {
    return {
      errors: {},
    };
  },
  computed: {
    buttonName: function () {
      const currentRoute = this.$route.name;

      if (
        currentRoute === "ContactDetails" ||
        currentRoute === "InclusivityInformation"
      ) {
        return "Continue";
      } else {
        return "Submit";
      }
    },
  },
  methods: {
    ...mapMutations("volunteer", ["resetVolunteerStore"]),
    ...mapGetters("volunteer", [
      "getContactDetails",
      "getVolunteerInformation",
      "getVolunteerDevice",
      "getInclusivityDetails",
    ]),

    onButtonClick() {
      const currentRoute = this.$route.name;

      if (currentRoute === "ContactDetails") {
        this.goToInclusivityInformationScreen();
      } else if (currentRoute === "InclusivityInformation") {
        this.goToVolunteerInformation();
      } else {
        this.submitQuestionnaire();
      }
    },

    goToInclusivityInformationScreen() {
      const { errors, isValid } = QuestionnaireValidation.validateContactPage(
        this.$store.state.volunteer
      );

      this.errors = errors;

      if (isValid) {
        router.push({ name: "InclusivityInformation" });
      }
    },

    goToVolunteerInformation() {
      router.push({ name: "VolunteerInformation" });
    },

    submitQuestionnaire() {
      const { errors, isValid } = QuestionnaireValidation.validateOnSubmit(
        this.$store.state.volunteer
      );
      this.errors = errors;
      if (isValid) {
        QuestionnaireService.submitQuesionnaire(
          this.getContactDetails(),
          this.getInclusivityDetails(),
          this.getVolunteerInformation(),
          this.getVolunteerDevice()
        )
          .then(() => {
            this.resetVolunteerStore();
            router.replace({ name: "ThankYou" });
          })
          .catch((error) => console.log("error", error));
      }
    },
  },
};
</script>

<style scoped>
.validation-message {
  display: flex;
  flex-direction: column;
  background: #f7eaeb;
  border: 1px solid red;
  border-radius: 10px;
  align-items: center;
}
.validation-message > p {
  font-weight: bold;
}

.validation-message li {
  list-style-type: none;
}
</style>
