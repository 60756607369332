<template>
  <Section>
    <FormLabel>Do you have any difficulties with your hearing?</FormLabel>
    <RadioButtonGroup
      :options="yesNotNoSureEnum"
      labelField="label"
      valueField="value"
      :value="difficultyHearing"
      @update:value="assignValue('difficultyHearing', $event)"
      name="difficultyHearing"
    />
  </Section>

  <Section v-show="difficultyHearing == 'YES'">
    <FormLabel>What ear is your hearing loss in?</FormLabel>
    <RadioButtonGroup
      :options="hearingLossEarEnum"
      labelField="label"
      valueField="value"
      :value="hearingLossEar"
      @update:value="assignValue('hearingLossEar', $event)"
      name="hearingLossEar"
    />
  </Section>

  <Section v-show="difficultyHearing == 'YES'">
    <FormLabel
      >Approximately how long ago (in years) did your hearing loss
      start?</FormLabel
    >
    <DropdownList
      v-if="hearingLossSinceValues.length > 0"
      :options="hearingLossSinceValues"
      :value="hearingLossSince"
      optionValue="id"
      optionLabel="label"
      name="Hearing loss since"
      @update:value="assignValue('hearingLossSince', $event)"
    />
  </Section>
</template>

<script>
import Section from "@shared_components/form/Section.vue";
import RadioButtonGroup from "@shared_components/form/RadioButtonGroup.vue";
import DropdownList from "@shared_components/form/DropdownList.vue";
import FormLabel from "@shared_components/form/FormLabel.vue";
import InputValues from "@volunteer/services/inputvalues.service.js";
import HearingLossEarEnum from "@volunteer/enums/hearingLossEarEnum.js";
import YesNoNotSureEnum from "@volunteer/enums/yesNoNotSureEnum.js";
import { mapState } from "vuex";

export default {
  name: "HearingLossQuestions",
  components: {
    Section,
    RadioButtonGroup,
    DropdownList,
    FormLabel,
  },
  data() {
    return {
      hearingLossSinceValues: [],
      hearingLossEarEnum: HearingLossEarEnum,
      yesNotNoSureEnum: YesNoNotSureEnum,
    };
  },
  async created() {
    this.hearingLossSinceValues = await InputValues.getHearingLossSinceValues();
  },
  computed: {
    ...mapState("volunteer", [
      "difficultyHearing",
      "hearingLossEar",
      "hearingLossSince",
    ]),
  },
  methods: {
    assignValue(name, value) {
      const payload = {
        prop: name,
        value: value,
      };
      this.$store.commit("volunteer/assignValue", payload);
    },
  },
};
</script>
