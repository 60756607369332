<template>
  <h3 class="subheading">
    <slot></slot>
  </h3>
</template>

<script>
export default {
  name: "Subheading",
};
</script>

<style scoped>
h3 {
  color: #222222;
  white-space: nowrap;
}
</style>
