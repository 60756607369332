<template>
  <div class="checkbox-group">
    <Checkbox
      class="inner-checkbox"
      v-for="option in options"
      :item="option"
      :label="labelField ? option[labelField] : option"
      :key="option"
      :name="name"
      :selectedValues="selectedValues"
      :selectedValue="selectedValue"
    />
  </div>
</template>

<script>
import Checkbox from "@shared_components/Checkbox.vue";
export default {
  name: "CheckboxButtonGroup",
  components: { Checkbox },
  props: ["options", "selectedValues", "name", "labelField", "selectedValue"],
};
</script>
