<template>
  <Header></Header>
  <div class="content">
    <router-view />
  </div>
</template>

<script>
import Header from "@shared_components/Header";

export default {
  name: "HEAR",
  components: {
    Header,
  },
};
</script>

<style>
body {
  margin: 0px;
  color: #666666;
  background-image: url("~@shared_assets/background.svg");
  background-repeat: no-repeat;
  background-position: right -100px top -100px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content {
  padding-left: 15%;
  padding-right: 15%;
}

.circle-blue {
  transform: rotate(180deg) translate(-100px, -150px);
  transform-origin: 300px 300px;
  mix-blend-mode: multiply;
}

@media only screen and (max-width: 600px) {
  .content {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (min-width: 768px) {
  body {
    background-size: 500px 500px;
  }
}
@media only screen and (min-width: 300px) {
  body {
    background-size: 350px 350px;
  }
}

@media only screen and (min-width: 480px) {
  body {
    background-size: 400px 400px;
  }
}

@media only screen and (min-width: 992px) {
  body {
    background-size: 550px 550px;
  }
}

@media only screen and (min-width: 2000px) {
  body {
    background-size: 600px 600px;
  }
}
</style>
