<template>
  <Heading>Your Involvement</Heading>

  <div>
    <Subheading
      >What would I have to do if I joined the volunteer database?</Subheading
    >

    <Text>
      If you agree to be part of our volunteer database, we will ask you to
      complete a consent form to say that you have read this information and
      agree to us contacting you about relevant research studies that you may
      like to take part in. We will also ask you to complete a short
      questionnaire about your hearing health. Subject to the current Covid
      guidance at the time you will be invited to have a standard hearing test,
      called an audiogram, at the University of Manchester. This would be
      conducted by a Research Audiologist and would take no more than 30
      minutes. This will give us more detailed information about your level of
      hearing and help researchers to find the appropriate people to invite to
      participate in their research studies.
    </Text>

    <Subheading>What happens if you find a problem with my hearing?</Subheading>

    <Text>
      If you are attending for a hearing test and we find something which you
      didn’t know about, the Research Audiologist will discuss it with you and
      give you a letter to take to your GP or Audiologist.
    </Text>

    <Subheading>Will I be paid for joining the database?</Subheading>
    <Text>
      You will not be paid for joining the database, however, if you then attend
      for a research study you will often be reimbursed for your time and
      refunded reasonable travel expenses.
    </Text>

    <Subheading
      >What sort of research will I be invited to take part in?</Subheading
    >
    <Text>
      Our particular areas of interest include developing new techniques to
      improve clinical practice as well as endeavouring to gain a better
      understanding of how we detect, process and make use of sounds. Studies
      may, for example, involve different types of hearing, listening and
      balance tests, as well as questionnaires, surveys or opinions, which may
      be offered online.
    </Text>

    <Text>
      When you are contacted about a particular study, you will be given
      information about how you can get involved; there is no obligation to take
      part if you do not want to.
    </Text>

    <Subheading>How often will I be contacted?</Subheading>

    <Text>
      This varies depending on how frequently researchers require participants
      with your characteristics. You may receive a couple of emails within a
      month, or none for a few months. We aim to email/send you our database
      newsletter 4 times per year, in order to keep you up-to-date with what
      projects are ongoing and of the outcome of previous projects that our
      volunteers have been involved with.
    </Text>

    <Subheading
      >What happens if I do not want to take part or if I change my
      mind?</Subheading
    >
    <Text>
      It is up to you to decide whether or not to join the Hearing Health
      Research Volunteer Database. If you do decide to take part after reading
      this information, you will be asked to sign a consent form (either online
      or on paper). Once you have decided to take part you are still free to
      withdraw at any time without giving a reason and without detriment to
      yourself. If you change your mind at any point, and want to be removed
      from the volunteer database, simply inform the database managers by
      emailing
      <a href="mailto:hearingresearch@manchester.ac.uk"
        >hearingresearch@manchester.ac.uk
      </a>
      or by contacting us at the address below.
    </Text>
  </div>
</template>
<script>
import Heading from "@shared_components/Heading.vue";
import Subheading from "@shared_components/Subheading.vue";
import Text from "@shared_components/Text.vue";
export default {
  name: "Involvement",
  components: {
    Heading,
    Subheading,
    Text,
  },
};
</script>
