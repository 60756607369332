<template>
  <Section>
    <FormLabel>Do you suffer from any balance disorders?</FormLabel>
    <RadioButtonGroup
      :options="yesNoNotSureEnum"
      labelField="label"
      valueField="value"
      :value="balanceDisorder"
      @update:value="assignValue('balanceDisorder', $event)"
      name="balanceDisorder"
    />
  </Section>

  <Section>
    <FormLabel>Please provide details if possible</FormLabel>
    <TextArea
      :value="balanceDisorderDetails"
      @onChange="assignValue('balanceDisorderDetails', $event)"
    />
  </Section>
</template>

<script>
import Section from "@shared_components/form/Section.vue";
import RadioButtonGroup from "@shared_components/form/RadioButtonGroup.vue";
import TextArea from "@shared_components/form/TextArea.vue";
import FormLabel from "@shared_components/form/FormLabel.vue";
import YesNoNotSureEnum from "@volunteer/enums/yesNoNotSureEnum.js";
import { mapState } from "vuex";

export default {
  name: "BalanceDisordersQuestions",
  components: {
    Section,
    RadioButtonGroup,
    TextArea,
    FormLabel,
  },
  computed: {
    ...mapState("volunteer", ["balanceDisorder", "balanceDisorderDetails"]),
  },
  data() {
    return { yesNoNotSureEnum: YesNoNotSureEnum };
  },
  methods: {
    assignValue(name, value) {
      const payload = {
        prop: name,
        value: value,
      };
      this.$store.commit("volunteer/assignValue", payload);
    },
  },
};
</script>
