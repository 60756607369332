<template>
  <div id="heading">
    <div class="title-section">
      <img
        :src="hearingHealthLogo"
        alt="Hearing Health Logo"
        class="logo"
        @click="onHeaderClickEvent"
      />
    </div>

    <div class="menu" v-if="isResearcherInterface && isAuthenticated">
      <p @click="openMenu" ref="contextMenu" class="popup-menu-label">
        <FontAwesomeIcon class="fa-lg" icon="user-circle" />
        {{ name }}
        <FontAwesomeIcon icon="angle-down" />
      </p>
      <PopupMenuModal
        :display="showHeaderMenu"
        :ref="'header-menu'"
        class="popup-menu"
      >
        <p @click="changePassword">Change password</p>
        <p @click="logout">Logout</p>
      </PopupMenuModal>
    </div>
  </div>
</template>

<script>
import PopupMenuModal from "@shared_components/PopupMenuModal.vue";
import hearingHealthLogo from "@shared_assets/hearinghealth.svg";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

library.add(faAngleDown, faAngleUp, faUserCircle);

export default {
  name: "Header",
  props: [
    "isResearcherInterface",
    "isAuthenticated",
    "name",
    "headerClickEvent",
  ],
  components: {
    PopupMenuModal,
    FontAwesomeIcon,
  },
  data() {
    return {
      showHeaderMenu: false,
    };
  },
  setup() {
    return {
      hearingHealthLogo,
    };
  },
  methods: {
    onHeaderClickEvent() {
      if (this.headerClickEvent) {
        this.headerClickEvent();
      }
    },
    changePassword() {
      this.$emit("changePassword");
    },
    logout() {
      this.$emit("logout");
    },
    openMenu() {
      const menuComponent = this.$refs["header-menu"];

      const { left, top, bottom, right } =
        this.$refs["contextMenu"].getBoundingClientRect();

      const isMenuOpened = menuComponent.show;

      if (isMenuOpened) {
        menuComponent.close();
      } else {
        menuComponent.open((right + left) / 2, (top + bottom) / 2 + 10);
      }
    },
  },
};
</script>

<style scoped>
.logo {
  cursor: pointer;
}
.popup-menu-label {
  color: white;
  font-weight: bold;
}
.menu {
  padding-right: 15%;
  cursor: pointer;
}
.popup-menu p {
  padding: 4px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 0.8em;
  font-weight: bold;
}
.popup-menu p:hover {
  background-color: rgb(223, 223, 223);
}

#heading {
  display: flex;
  border-bottom: 1px solid #28a0d8;
  background-color: #28a0d8;
  height: 50px;
  justify-content: space-between;
}

.title-section {
  display: flex;
  align-items: center;
  padding-left: 15%;
}

.title-section .logo {
  height: 50%;
}

@media only screen and (max-width: 600px) {
  .title-section {
    padding-left: 0px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
