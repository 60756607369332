<template>
  <Heading>Data Protection and Confidentiality</Heading>

  <div>
    <Subheading>What information will you collect about me?</Subheading>
    <Text>
      In order for you to be on the volunteer database, we will need to collect
      some or all of the following information that could identify you, called
      "personal identifiable information":
    </Text>
    <ol>
      <li>Name, sex, date of birth, and communication requirements.</li>
      <li>
        Contact details (e.g. address, email, telephone number), so that we can
        contact you with research study information and arrange any appointments
        with you.
      </li>
      <li>Details of your hearing level (if tested).</li>
      <li>
        Information from the hearing health questionnaire that you have
        completed, for example, history of tinnitus, balance disorders, and use
        of any hearing devices.
      </li>
    </ol>

    <Text
      >We will also ask to collect some demographic data in order for us to
      monitor inclusion, equality and diversity of our participants. It is
      entirely optional as to whether you wish to provide the following
      information to us:</Text
    >

    <ol>
      <li>Ethnicity</li>
      <li>Religion</li>
      <li>Employment status</li>
      <li>Educational level</li>
      <li>Gender</li>
      <li>Sexual orientation</li>
      <li>Disability</li>
    </ol>

    <Subheading
      >Under what legal basis are you collecting this information?</Subheading
    >
    <Text>
      We are collecting and storing this personal identifiable information in
      accordance with data protection law which protect your rights. These state
      that we must have a legal basis (specific reason) for collecting your
      data. For the database, the specific reason is that it is "a public
      interest task" and "a process necessary for research purposes".
    </Text>
    <Text :isBold="true"
      >The additional demographic data is optional for monitoring purposes and
      is not a requirement in order to take part in our research.</Text
    >

    <Subheading>
      What are my rights in relation to the information you will collect about
      me?
    </Subheading>

    <Text>
      You have a number of rights under data protection law regarding your
      personal information. For example, you can request a copy of the
      inormation we hold about you, including your hearing test. This is known
      as a Subject Access Request. If you would like to know more about your
      different rights, the way we use your personal information to ensure we
      follow the law, please consult our
      <a href="https://documents.manchester.ac.uk/display.aspx?DocID=37095"
        >Privacy Notice for Research</a
      >.
    </Text>

    <Subheading>
      Will my participation on the database be confidential and my personal
      identifiable information be protected?
    </Subheading>

    <Text>
      In accordance with data protection law, The University of Manchester is
      the Data Controller for this project. This means that we are responsible
      for making sure your personal information is kept secure, confidential and
      used only in the way you have been told it will be used. All researchers
      are trained with this in mind, and your data will be looked after in the
      following way:
    </Text>

    <ul>
      <li>
        The Database data will be hosted in a secure environment by an UoM
        approved supplier. All data will only be stored in UK datacentres. Data
        will not be transferred to any other platforms or facilities.
      </li>
      <li>
        The database managers have access to all of the above personal
        information, but this is password-protected.
      </li>
      <li>
        Your record on the database will be allocated an ID number as soon as
        you submit your online form, or as soon as your information is entered
        onto the electronic database (where application has been on paper).
      </li>

      <li>
        Researchers who are granted access to the database by the managers will
        be able to view your ID number and 3-4 (see above) initially. If you are
        then identified as a potential participant, the researcher will have
        access to view your name, date of birth and email address (or other
        contact). Researcher access to the database is also password-protected.
      </li>

      <li>
        Paper copies of hearing test results (where performed), which are
        anonymised at the time they are recorded, will be kept in a locked
        filing cabinet for as long as you remain on the database.
      </li>

      <li>
        Consent forms (when completed on paper) and receipts of any payment will
        also be kept in a locked filing cabinet for as long as you remain on the
        database.
      </li>
      <li>
        We aim to contact you every 2 years to ensure you are happy to remain on
        the database.
      </li>

      <li>
        If you decide to come off the database, your online record will be
        deleted, and your paper hearing test will be disposed of in confidential
        waste. If, during your time on the database, you consent to participate
        in a study, the data collected for that study will not be automatically
        deleted when you are removed from the database. This data is stored and
        processed separately according to the data management plan for the
        particular study. You would always be made aware of this prior to giving
        your consent for a study.
      </li>
    </ul>

    <Text>
      Please also note that individuals from The University of Manchester or
      regulatory authorities may need to look at the information collected for
      this study to make sure the project is being carried out as planned. This
      may involve looking at identifiable data. All individuals involved in
      auditing and monitoring the project will have a strict duty of
      confidentiality to you as a research volunteer.
    </Text>
  </div>
</template>

<script>
import Heading from "@shared_components/Heading.vue";
import Subheading from "@shared_components/Subheading.vue";
import Text from "@shared_components/Text.vue";
export default {
  name: "DataProtection",
  components: {
    Heading,
    Subheading,
    Text,
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}

ul li::before {
  content: "\25A0";
  color: #4ea1d6;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
li {
  padding-bottom: 10px;
  color: #333;
}
</style>
