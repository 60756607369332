<template>
  <div class="input-item">
    <input
      ref="telephoneinput"
      class="input-field"
      type="tel"
      :name="name"
      :value="value"
      @keypress="validateInput"
      @input="setValue"
    />
  </div>
</template>

<script>
export default {
  name: "TelephoneField",
  props: ["value", "name"],
  data: function () {
    return {
      phoneNumber: null,
    };
  },
  methods: {
    setValue(event) {
      let newValue = event.target.value;
      
      const validatedValue = newValue;
      this.$emit("onChange", validatedValue);
    },
    validateInput(event) {
      const inputPosition = this.$refs.telephoneinput.selectionStart;
      let key = event.key;

      if (key == "+" && inputPosition == 0) {
        return;
      }

      key = +key;
      this.preventInputIfValueIsNotNumber(key, event);
    },
    preventInputIfValueIsNotNumber(keyPressed, event) {
      if (Number.isNaN(keyPressed)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
.input-item {
  padding-top: 5px;
  padding-bottom: 5px;
}
.input-field {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 57px;
  font-size: 21px;
  padding-left: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
}
</style>
