<template>
  <Title>Questionnaire is completed. Thank you for your time.</Title>
</template>

<script>
import Title from "@shared_components/Title.vue";

export default {
  name: "ThankYou",
  components: {
    Title,
  },
};
</script>
