<template>
  <div class="input-item">
    <input
      class="text-input"
      :type="type ? type : 'text'"
      v-model="inputValue"
      :placeholder="placeholder"
      :name="name"
      :maxlength="maxlength"
    />
  </div>
</template>

<script>
export default {
  name: "TextField",
  props: ["value", "name", "type", "placeholder", "maxlength"],
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      },
    },
  },
};
</script>

<style scoped>
.input-item {
  padding-top: 5px;
  padding-bottom: 5px;
}
.text-input {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 57px;
  font-size: 21px;
  padding-left: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
}
</style>
