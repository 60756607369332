<template>
  <Title>Inclusivity, Diversity and Equality</Title>

  <p>
    The following information will allow us to monitor inclusivity, diversity
    and equality in our research study recruitment,
    <strong>completion is optional</strong>
  </p>

  <Section>
    <FormLabel
      >Which of the following best describes how you think of
      yourself?</FormLabel
    >
    <DropdownList
      data-cy="ethnicity-picker"
      v-if="ethnicities.length > 0"
      :options="ethnicities"
      :value="ethnicity"
      optionValue="id"
      optionLabel="label"
      name="ethnicity"
      @update:value="assignValue('ethnicity', $event)"
    />

    <TextField
      data-cy="ethnicity-textbox"
      v-if="displayOtherEthnicityInput"
      type="text"
      @update:value="assignValue('ethnicityOther', $event)"
      name="ethnicityOther"
      :value="ethnicityOther"
      placeholder="Other Ethnic Group"
    />
  </Section>

  <Section>
    <FormLabel
      >Which of the following options best describes your religion or
      belief?</FormLabel
    >
    <DropdownList
      data-cy="religion-picker"
      v-if="religions.length > 0"
      :options="religions"
      :value="religion"
      optionValue="id"
      optionLabel="label"
      name="religion"
      @update:value="assignValue('religion', $event)"
    />
  </Section>

  <Section>
    <FormLabel>Are you currently?</FormLabel>
    <DropdownList
      data-cy="employment status-picker"
      v-if="employmentStatuses.length > 0"
      :options="employmentStatuses"
      :value="employmentStatus"
      optionValue="id"
      optionLabel="label"
      name="employmentStatus"
      @update:value="assignValue('employmentStatus', $event)"
    />

    <TextField
      data-cy="job title-textbox"
      v-if="displayJobTitleInput"
      type="text"
      @update:value="assignValue('jobTitle', $event)"
      name="jobTitle"
      :value="jobTitle"
      placeholder="Please write your job title here"
    />

    <TextField
      data-cy="employment status-textbox"
      v-if="displayOtherEmploymentStatusInput"
      type="text"
      @update:value="assignValue('employmentStatusOther', $event)"
      name="employmentStatusOther"
      :value="employmentStatusOther"
      placeholder="Please type other employment status here"
    />
  </Section>

  <Section>
    <FormLabel
      >What is the highest education level you have attained?</FormLabel
    >
    <DropdownList
      data-cy="education-picker"
      v-if="educationLevels.length > 0"
      :options="educationLevels"
      :value="educationLevel"
      optionValue="id"
      optionLabel="label"
      name="educationLevel"
      @update:value="assignValue('educationLevel', $event)"
    />
  </Section>

  <Section>
    <FormLabel>Which best describes your gender?</FormLabel>
    <DropdownList
      data-cy="gender-picker"
      v-if="genderTypes.length > 0"
      :options="genderTypes"
      :value="gender"
      optionValue="id"
      optionLabel="label"
      name="gender"
      @update:value="assignValue('gender', $event)"
    />
    <TextField
      data-cy="gender-textbox"
      v-if="displayOtherGenderInput"
      type="text"
      @update:value="assignValue('genderOther', $event)"
      name="genderOther"
      :value="genderOther"
      placeholder="Type the other gender"
    />
  </Section>

  <Section>
    <FormLabel
      >Which of the following options best describes how you think about
      yourself?</FormLabel
    >
    <DropdownList
      data-cy="sexual orientation-picker"
      v-if="sexualOrientationTypes.length > 0"
      :options="sexualOrientationTypes"
      :value="sexual_orientation"
      optionValue="id"
      optionLabel="label"
      name="sexual_orientation"
      @update:value="assignValue('sexual_orientation', $event)"
    />
  </Section>

  <Section>
    <FormLabel
      >Under the Equality Act 2010, the definition of disability is
      <i
        >“if you have a physical or mental impairment that has a substantial and
        long term adverse effect on your ability to carry out normal day to day
        activity”</i
      >. According to this definition, do you consider yourself to have a
      disability?
    </FormLabel>

    <DropdownList
      data-cy="disability-picker"
      :options="['Yes', 'No', 'Prefer not to say']"
      :value="disability"
      name="disability"
      @update:value="assignValue('disability', $event)"
    />
  </Section>
</template>

<script>
import Title from "@shared_components/Title.vue";
import Section from "@shared_components/form/Section.vue";
import FormLabel from "@shared_components/form/FormLabel.vue";
import DropdownList from "@shared_components/form/DropdownList.vue";
import TextField from "@shared_components/form/TextField.vue";

import InputValues from "@volunteer/services/inputvalues.service.js";
import { mapState } from "vuex";

export default {
  name: "InclusivityInformation",
  components: {
    Title,
    Section,
    FormLabel,
    TextField,
    DropdownList,
  },
  data() {
    return {
      ethnicities: [],
      religions: [],
      employmentStatuses: [],
      educationLevels: [],
      genderTypes: [],
      sexualOrientationTypes: [],
      date: new Date(),
      attrs: [
        {
          dates: { start: new Date(1900, 0, 1), end: new Date() },
        },
      ],
    };
  },
  async created() {
    InputValues.getEthnicityValues().then((response) => {
      this.ethnicities = response.data;
    });

    InputValues.getReligionValues().then((response) => {
      this.religions = response.data;
    });

    InputValues.getEmploymentValues().then((response) => {
      this.employmentStatuses = response.data;
    });

    InputValues.getEducationLevelValues().then((response) => {
      this.educationLevels = response.data;
    });

    InputValues.getGenderValues().then((response) => {
      this.genderTypes = response.data;
    });

    InputValues.getSexualOrientationValues().then((response) => {
      this.sexualOrientationTypes = response.data;
    });
  },
  computed: {
    ...mapState("volunteer", [
      "sexAtBirth",
      "handedness",
      "computerAccess",
      "otherRelevantInformation",
      "heardFrom",
      "dob",
      "ethnicity",
      "ethnicityOther",
      "religion",
      "employmentStatus",
      "jobTitle",
      "otherEmploymentStatus",
      "educationLevel",
      "gender",
      "genderOther",
      "sexual_orientation",
    ]),
    displayOtherEthnicityInput() {
      const ethnicity = this.ethnicities.find((ethnicity) => {
        return ethnicity.id == this.ethnicity;
      });

      return ethnicity && ethnicity.name === "other";
    },
    displayJobTitleInput() {
      const employmentStatus = this.employmentStatuses.find(
        (employmentStatus) => {
          return employmentStatus.id == this.employmentStatus;
        }
      );

      return employmentStatus && employmentStatus.name === "working";
    },
    displayOtherEmploymentStatusInput() {
      const employmentStatus = this.employmentStatuses.find(
        (employmentStatus) => {
          return employmentStatus.id == this.employmentStatus;
        }
      );

      return employmentStatus && employmentStatus.name === "other";
    },
    displayOtherGenderInput() {
      const gender = this.genderTypes.find((genderType) => {
        return genderType.id == this.gender;
      });

      return gender && gender.name === "other";
    },
  },

  methods: {
    assignValue(name, value) {
      const payload = {
        prop: name,
        value: value,
      };
      this.$store.commit("volunteer/assignValue", payload);
    },
  },
};
</script>

<style scoped>
/deep/ .dob .input-field {
  display: block !important;
  position: relative !important;
  width: 90px !important;
  height: 50px !important;
}

.dob-subsection {
  display: block;
  float: left;
  width: 100px !important;
  height: 100px !important;
}

.sab-section {
  clear: both;
}
</style>
