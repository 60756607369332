<template>
  <textarea
    name="subtitle"
    style="resize: none"
    rows="5"
    v-model="textareaValue"
  >
  </textarea>
</template>

<script>
export default {
  name: "TextArea",
  components: {},
  props: ["value"],
  computed: {
    textareaValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("onChange", newValue);
      },
    },
  },
};
</script>

<style scoped>
textarea {
  display: "block";
  width: 100%;
  height: 136px;
  font-size: 21px;
}
</style>
