import { createRouter, createWebHistory } from "vue-router";
import ConsentPage from "../views/ConsentPage.vue";
import Questionnaire from "../views/Questionnaire.vue";
import VolunteerInformation from "../views/VolunteerInformation.vue";
import InclusivityInformation from "../views/InclusivityInformation.vue"
import ContactDetails from "../views/ContactDetails.vue";
import ThankYou from "../views/ThankYou.vue";

const routes = [
  {
    path: "/",
    name: "ConsentPage",
    component: ConsentPage,
  },
  {
    path: "/questionnaire",
    name: "Questionnaire",
    component: Questionnaire,
    children: [
      {
        path: "/questionnaire/1",
        name: "ContactDetails",
        component: ContactDetails,
      },
      {
        path: "/questionnaire/2",
        name: "InclusivityInformation",
        component: InclusivityInformation,
      },
      {
        path: "/questionnaire/3",
        name: "VolunteerInformation",
        component: VolunteerInformation,
      },
    ],
  },
  {
    path: "/thankyou",
    name: "ThankYou",
    component: ThankYou,
  },
];

const router = createRouter({
  base: location.pathname,
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
