<template>
  <div class="form-section">
    <label v-if="label">{{ label }}</label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Section",
  props: ["label"],
};
</script>

<style scoped>
.form-section {
  padding-top: 10px;
  padding-bottom: 30px;
}
label {
  display: block;
  font-size: 24px;
  font-weight: bold;
  color: #222222;
  padding-bottom: 10px;
}
</style>
