<template>
  <Title>Volunteer Information</Title>

  <p>
    The following information will allow us to identify which studies may be
    suitable for you.
  </p>

  <Section>
    <FormLabel>Date of birth</FormLabel>

    <v-date-picker
      :modelValue="dob"
      :min-date="new Date(1900, 0, 1)"
      :max-date="maxDate"
      @update:modelValue="assignValue('dob', $event)"
      :update-on-input="false"
      :popover="{ visibility: 'click' }"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <TextField
          type="text"
          class="bg-white border px-2 py-1 rounded"
          :value="inputValue"
          v-on="inputEvents"
          data-cy="datepicker"
          @update:value="inputEvents"
        />
      </template>
    </v-date-picker>
  </Section>

  <Section class="sab-section">
    <FormLabel>Sex at birth</FormLabel>
    <RadioButtonGroup
      :options="['Male', 'Female', 'Other']"
      :value="sexAtBirth"
      @update:value="assignValue('sexAtBirth', $event)"
      name="sexAtBirth"
    />
  </Section>

  <HearingLossQuestions />
  <HearingDeviceQuestions />
  <TinnitusQuestions />
  <BalanceDisordersQuestions />

  <Section>
    <FormLabel>What handedness are you?</FormLabel>
    <RadioButtonGroup
      :options="['Left', 'Right', 'Ambidextrous']"
      :value="handedness"
      @update:value="assignValue('handedness', $event)"
      name="handedness"
    />
  </Section>

  <Section>
    <FormLabel
      >Some of our studies may require access to tests or surveys on a computer.
      Do you have access to a computer and the internet?</FormLabel
    >
    <RadioButtonGroup
      :options="['Yes', 'No']"
      value="computerAccess"
      @update:value="assignValue('computerAccess', $event)"
      name="computerAccess"
    />
  </Section>

  <Section>
    <FormLabel>Where did you hear about us?</FormLabel>
    <DropdownList
      v-if="advertisementSources.length > 0"
      :options="advertisementSources"
      :value="heardFrom"
      optionValue="id"
      optionLabel="label"
      name="heardFrom"
      @update:value="assignValue('heardFrom', $event)"
    />
  </Section>

  <Section>
    <FormLabel
      >Any further relevant details you would like us to be aware of (e.g.
      medical details, disabilities, preferred days/times for visits, translator
      required or other)</FormLabel
    >
    <TextArea
      :value="otherRelevantInformation"
      @onChange="assignValue('otherRelevantInformation', $event)"
    />
  </Section>
</template>

<script>
import Title from "@shared_components/Title.vue";
import Section from "@shared_components/form/Section.vue";
import RadioButtonGroup from "@shared_components/form/RadioButtonGroup.vue";
import DropdownList from "@shared_components/form/DropdownList.vue";
import TextArea from "@shared_components/form/TextArea.vue";
import FormLabel from "@shared_components/form/FormLabel.vue";

import HearingLossQuestions from "./VolunteerInformation/HearingLossQuestions.vue";
import HearingDeviceQuestions from "./VolunteerInformation/HearingDeviceQuestions.vue";
import TinnitusQuestions from "./VolunteerInformation/TinnitusQuestions.vue";
import BalanceDisordersQuestions from "./VolunteerInformation/BalanceDisordersQuestions.vue";
import TextField from "@shared_components/form/TextField.vue";

import InputValues from "@volunteer/services/inputvalues.service.js";
import "v-calendar/dist/style.css";
import { mapState } from "vuex";

export default {
  name: "VolunteerInformation",
  components: {
    Title,
    Section,
    RadioButtonGroup,
    HearingLossQuestions,
    HearingDeviceQuestions,
    TinnitusQuestions,
    BalanceDisordersQuestions,
    DropdownList,
    TextArea,
    FormLabel,
    TextField,
  },
  data() {
    var maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    return {
      advertisementSources: [],
      date: new Date(),
      maxDate: maxDate,
      attrs: [
        {
          dates: { start: new Date(1900, 0, 1), end: new Date() },
        },
      ],
    };
  },
  async created() {
    this.advertisementSources = await InputValues.getAdvertisementSources();
  },
  computed: {
    ...mapState("volunteer", [
      "sexAtBirth",
      "handedness",
      "computerAccess",
      "otherRelevantInformation",
      "heardFrom",
      "dob",
    ]),
  },
  methods: {
    assignValue(name, value) {
      const payload = {
        prop: name,
        value: value,
      };
      this.$store.commit("volunteer/assignValue", payload);
    },
  },
};
</script>

<style scoped>
/deep/ .dob .input-field {
  display: block !important;
  position: relative !important;
  width: 90px !important;
  height: 50px !important;
}

.dob-subsection {
  display: block;
  float: left;
  width: 100px !important;
  height: 100px !important;
}

.sab-section {
  clear: both;
}
</style>
