<template>
  <Heading>Contact Details</Heading>
  <Text>
    If you have any queries about the database or if you are interested in
    joining, please contact the database managers (Helen Whiston and Melanie
    Lough) at:
  </Text>

  <ul>
    <li>NIHR Manchester Biomedical Research Centre</li>
    <li>Manchester Centre for Audiology and Deafness (ManCAD)</li>
    <li>School of Health Sciences</li>
    <li>Room A4.02 Ellen Wilkinson Building</li>
    <li>University of Manchester</li>
    <li>Oxford Road</li>
    <li>Manchester</li>
    <li>M13 9PL</li>
    <li>Tel: 0161 275 0516/0172</li>
    <li>
      email:
      <a href="mailto:hearingresearch@manchester.ac.uk"
        >hearingresearch@manchester.ac.uk</a
      >
    </li>
  </ul>
</template>

<script>
import Heading from "@shared_components/Heading.vue";
import Text from "@shared_components/Text.vue";

export default {
  name: "ContactDetails",
  components: {
    Heading,
    Text,
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}

li {
  font-weight: bold;
}
</style>
