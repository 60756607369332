<template>
  <Heading>Complaints</Heading>
  <Text>
    If you have a complaint, please contact the database managers (Helen Whiston
    and Melanie Lough) by emailing:
    <a href="mailto:hearingresearch@manchester.ac.uk"
      >hearingresearch@manchester.ac.uk
    </a>
    , tel: 0161 275 0516/0172
  </Text>

  <Text>
    If you wish to make a formal complaint to someone independent of the
    database management team, or if you are not satisfied with the response you
    have gained from the database managers, then please contact:
  </Text>

  <Text>
    The Research Ethics Manager, Research Office, Christie Building, The
    University of Manchester, Oxford Road, Manchester, M13 9PL, by emailing:
    <a href="mailto:researchcomplaints@manchester.ac.uk"
      >researchcomplaints@manchester.ac.uk
    </a>
    or by telephoning 0161 306 8089.
  </Text>

  <Text>
    If you wish to contact us about your data protection rights, please email
    <a href="mailto:dataprotection@manchester.ac.uk"
      >dataprotection@manchester.ac.uk
    </a>
    or write to The Information Governance Office, Christie Building, The
    University of Manchester, Oxford Road, M13 9PL at the University and we will
    guide you through the process of exercising your rights.
  </Text>

  <Text>
    You also have a right to complain to the Information Commissioner's Office
    about complaints relating to your personal identifiable information
    <a href="https://ico.org.uk/concerns">https://ico.org.uk/concerns</a>
    , tel. 0303 123 1113.
  </Text>

  <Text>
    This Project Has Been Approved by the University of Manchester's Research
    Ethics Committee 28/2/19 [Ref: 2019-2815-9564]
  </Text>
</template>

<script>
import Heading from "@shared_components/Heading.vue";
import Text from "@shared_components/Text.vue";
export default {
  name: "Complaints",
  components: {
    Heading,
    Text,
  },
};
</script>
