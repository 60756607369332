<template>
  <h1>
    <slot></slot>
  </h1>
</template>

<script>
export default {
  name: "Title",
};
</script>

<style scoped>
h1 {
  color: #28a0d8;
}
</style>
