import ApiService from "@shared_services/api.service.js";

const InputValues = {
  async getHearingLossSinceValues() {
    return (await ApiService.get("list/hearinglosssince")).data;
  },
  async getDeviceTypes() {
    return (await ApiService.get("list/devicetypes")).data;
  },
  async getAdvertisementSources() {
    return (await ApiService.get("list/advertisementsources")).data;
  },
  async getEthnicityValues() {
    return ApiService.get("list/ethnicity");
  },
  async getReligionValues() {
    return ApiService.get("list/religion");
  },
  async getEmploymentValues() {
    return ApiService.get("list/employment");
  },
  async getEducationLevelValues() {
    return ApiService.get("list/education");
  },
  async getGenderValues() {
    return ApiService.get("list/gender");
  },
  async getSexualOrientationValues() {
    return ApiService.get("list/sexual_orientation");
  },
};

export default InputValues;
