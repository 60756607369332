<template>
  <p :class="{ bold: isBold }">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: "Text",
  props: ["isBold"],
};
</script>

<style scoped>
p {
  color: #333;
  font-size: 16px;
}

.bold {
  font-weight: bold;
}
</style>
